import React from 'react';
import './FeaturesPage.css';
import AWS from 'aws-sdk';

const S3_BUCKET_NAME = 'rpc-features';
const REGION = 'us-east-1';

let logo = require("./IMG_6205.heic")

export class FeaturesPage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            imageUrls: []
        }
    }

    componentDidMount() {
        // Configure AWS SDK with the region
        AWS.config.update({
        accessKeyId: "AKIAVGM2RA7KFBEXT4AJ",
        secretAccessKey: "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",
        region: REGION
      });
  
      // Create an instance of the S3 service
      const s3 = new AWS.S3();
  
      // Function to list all files in the S3 bucket
      const listFiles = async () => {
        try {
          const params = {
            Bucket: S3_BUCKET_NAME,
            Prefix: 'features/'
          };
          
          const response = await s3.listObjectsV2(params).promise();
          const urls = response.Contents
            .filter(file => file.Key.endsWith('.jpg') || file.Key.endsWith('.jpeg') || file.Key.endsWith('.png')) // Filter only image files
            .map(file => {
              return `https://${S3_BUCKET_NAME}.s3.${REGION}.amazonaws.com/${file.Key}`;
            });
  
          this.setState({ imageUrls: urls })
        } catch (error) {
          console.log('Error listing files:', error);
        }
      };
  
      listFiles();
    }

    render() {
        return (
            <div id="features-image-container">
                {/* {this.state.imageUrls.map((url, index) => (
                <a href={url} target="_blank">
                  <div
                    key={index}
                    style={{
                      backgroundImage: `url(${url})`,
                      backgroundSize: 'cover',
                      margin:'5px',
                      backgroundRepeat: 'no-repeat',
                      width: '300px',
                      height: '300px',
                    }}
                    className="photo-div"
                  ></div>
                </a>
                ))} */}
                <h4>The Features page will be offline for about a week for maintenance. Thank you for your understanding!</h4>
            </div>
        )
    }
}